<template>
  <el-dialog class="myScreenDialog" :close-on-click-modal="false" append-to-body :visible.sync="isPreviewDialog" top="0vh" @close="closeHandle">
    <customBigScreen :data="currentItem" @close="closeHandle" :style="`width:${clientWidth}px;height: ${clientHeight}px;`"></customBigScreen>
  </el-dialog>
</template>
<script>
import systemapi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import customBigScreen from '@/components/bigScreen/customBigScreen/index.vue'
export default {
  props: ['id'],
  components: {
    customBigScreen
  },
  data () {
    return {
      isPreviewDialog: true,
      currentItem: {},
      clientHeight: 0,
      clientWidth: 0
    }
  },
  created () {
    this.clientHeight = document.body.clientHeight
    this.clientWidth = document.body.clientWidth
    console.log(document.body.clientWidth)
    this.fnGetBigScreenCustomizationDetail()
  },
  mounted () {

  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName']),
    ...mapState('menuList', ['projectInfo'])
  },
  watch: {},
  methods: {
    closeHandle () {
      this.isPreviewDialog = false
      this.$emit('close')
    },
    // 获取详情
    async fnGetBigScreenCustomizationDetail () {
      const res = await systemapi.getBigScreenCustomizationDetail({ _id: this.id })
      console.log('fnGetBigScreenCustomizationDetail-----', res)
      if (res && res.Code === 200) {
        this.currentItem = res.Data
      }
    }
  }
}
</script>
<style scoped lang="less">
@import url("~@/components/bigScreen/customBigScreen/index.less");
.custom_big_screen_box {
  display: block;
  // zoom: 0.95; /* Old IE only */
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  transform-origin: center;
}
.myScreenDialog {
  z-index: 9999;

  /deep/ .el-dialog {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    .el-pager li {
      background-color: transparent;
      box-shadow: none;
    }
  }
  /deep/ .el-dialog__header {
    display: none;
    width: calc(1920px * 0.8);
  }
  /deep/ .el-dialog__body {
    padding: 0px;
    background-color: transparent;
  }
}
</style>
