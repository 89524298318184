<template>
  <!-- 大屏自定义 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="大屏自定义：">
        <el-input maxlength="15" @keyup.enter.native="onSubmit" placeholder="请输入大屏自定义名称" :value="queryForm.screen_name"
          @input="(e) => (queryForm.screen_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="大屏自定义">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel">删除</lbButton>
          <lbButton icon="xinzeng" @click="goEditPage('')">新增</lbButton>
        </div>
      </template>
      <!-- 表格 -->
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border stripe
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
        <el-table-column prop="screen_name" label="大屏" min-width="220"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <div class="switch_box" @click="fnPublishBigScreenById(scope.row)">
              <div :class="scope.row.is_publish ? 'switch_check' : 'switch'"></div>
              <span :style="scope.row.is_publish ? 'color: #1acd90;' : 'color: #666666;'"> {{ scope.row.is_publish ?
                "已发布" : "未发布" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <span>{{ validDateTime(scope.row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="info" icon="sousuo" hint="预览" @click="handlePreview(scope.row)"></lbButton>
            <lbButton type="warning" icon="bianji" hint="编辑" @click="goEditPage(scope.row._id)"></lbButton>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
    </le-card>
    <Preview :id="previewId" v-if="isPreviewDialog" @close="isPreviewDialog = false"></Preview>
    <!-- 新增编辑自定义大屏 -->
    <lebo-dialog :title="dialogaddScreenCustomId ? '编辑自定义大屏' : '新增自定义大屏'" :isShow="showaddScreenCustom" width="95%" top="5vh"
      @close="showaddScreenCustom = false" footerSlot>
      <addScreenCustom v-if="showaddScreenCustom" :id="dialogaddScreenCustomId" @closeDialog="closeaddScreenCustom">
      </addScreenCustom>
    </lebo-dialog>
  </div>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import Preview from './components/previewScreen/index.vue'
import addScreenCustom from './components/addScreenCustom/index.vue'
export default {
  components: {
    Preview, addScreenCustom
  },
  data () {
    return {
      // 查询表单
      queryForm: {
        screen_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      TotalCount: 0,
      // 选中的表格数据
      multipleSelection: [],
      previewId: '',
      isPreviewDialog: false,
      showaddScreenCustom: false,
      dialogaddScreenCustomId: ''
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetBigScreenCustomizationPageList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    ...mapMutations('menuList', ['setRefreshBigScreent']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取自定义大屏列表
    async fnGetBigScreenCustomizationPageList () {
      const res = await systemApi.getBigScreenCustomizationPageList(this.queryForm)
      console.log('fnGetBigScreenCustomizationPageList-------', res)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
      } else {
        this.tableData = []
        this.TotalCount = 0
      }
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetBigScreenCustomizationPageList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetBigScreenCustomizationPageList()
    },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetBigScreenCustomizationPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (data) {
      this.multipleSelection = data
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的自定义大屏, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var arr = []
          this.multipleSelection.forEach(item => {
            arr.push(item._id)
          })
          this.fnDelBigScreenCustomization(arr)
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的自定义大屏！')
      }
    },
    async fnDelBigScreenCustomization (obj) {
      const res = await systemApi.delBigScreenCustomization(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.RefreshBigScreent()
      this.fnGetBigScreenCustomizationPageList()
    },
    RefreshBigScreent () {
      this.setRefreshBigScreent(true)
      this.$store.dispatch('menuList/fngetGroupBigScreen')
    },
    goEditPage (id) {
      this.dialogaddScreenCustomId = id
      this.showaddScreenCustom = true
    },
    // 发布
    async fnPublishBigScreenById (item) {
      const res = await systemApi.publishBigScreenById({
        id: item._id,
        is_publish: !item.is_publish // 发布状态 true:发布 False:下架
      })
      this.RefreshBigScreent()
      this.fnGetBigScreenCustomizationPageList()
    },
    // 预览
    handlePreview (item) {
      this.previewId = item._id
      this.isPreviewDialog = true
    },
    closeaddScreenCustom () {
      this.showaddScreenCustom = false
      this.fnGetBigScreenCustomizationPageList()
    }
  }
}
</script>
<style scoped lang="less">
.switch_box {
  display: flex;
  align-items: center;
  cursor: pointer;

  .switch {
    width: 35px;
    height: 18px;
    border-radius: 9px;
    margin-right: 10px;
    background-color: #cccccc;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translate(0, -50%);
    }
  }

  .switch_check {
    width: 35px;
    height: 18px;
    border-radius: 9px;
    margin-right: 10px;
    background-color: #1acd90;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 3px;
      transform: translate(0, -50%);
    }
  }
}</style>
