<template>
  <!-- 自定义大屏 -->
    <div class="box-card">
      <ScreenConfig ref="ScreenConfigRef" :data="screenConfigData" :backMap ="backGroundMap" @onsubmit="fnonsubmit"></ScreenConfig>
      <lbButton type="warning" fill class="checkButton3" @click="openScreenTemplate">从模板导入</lbButton>
      <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm" class="checkButton" @click="onSubmit()" v-preventReClick>保 存</lbButton>
      <!-- 从模板导入 -->
      <lebo-dialog  title="自定义大屏模板"  append-to-body :isShow="isScreenTemplate" width="30%" @close="isScreenTemplate=false" footerSlot>
        <el-table class="tablestyle" ref="multipleTable" :data="bigScreenTemplateList" align="center" header-align="center" tooltip-effect="dark" height="600" border stripe>
          <el-table-column prop="screen_name" label="平台菜单包"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="info" icon="daoru1" hint="确定导入" @click="importBigScreen(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
      </lebo-dialog>
    </div>
  </template>
<script>
import systemapi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import ScreenConfig from '@/components/screenCustom/screenConfig.vue'
export default {
  components: {
    ScreenConfig
  },
  props: ['id'],
  data () {
    return {
      screenConfigData: {},
      isScreenTemplate: false,
      bigScreenTemplateList: [], // 模板列表
      backGroundMap: {}
    }
  },
  created () {
    this.$nextTick(() => {
      const id = this.id
      if (id) {
        this.fnGetBigScreenCustomizationDetail(id)
      }
    })
  },
  mounted () {
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    ...mapMutations('menuList', ['setRefreshBigScreent']),
    // 获取详情
    async fnGetBigScreenCustomizationDetail (id) {
      const res = await systemapi.getBigScreenCustomizationDetail({ _id: id })
      if (res && res.Code === 200) {
        console.log('获取自定义大屏', res.Data)
        const filterMap = res.Data.children.filter(item => {
          if (item.block_enum === 28) {
            this.backGroundMap = item
          }
          return item.block_enum !== 28
        })
        res.Data.children = filterMap
        this.screenConfigData = res.Data
      }
    },
    // 保存提交
    onSubmit () {
      this.$refs.ScreenConfigRef.onsubmit()
    },
    async fnonsubmit () {
      var data = this.$refs.ScreenConfigRef.addForm
      var backMap = this.$refs.ScreenConfigRef.selectMapConfig
      // data.children.forEach(item => {
      //   item.standard_id = item._id
      // })
      data.children.push(backMap)
      // 不需要传递graph_type值
      data.children.map(item => {
        delete item.graph_type
      })
      if (this.$refs.ScreenConfigRef.addForm.screen_display_type === 2) {
        this.$refs.ScreenConfigRef.addForm.children = [] // 切换到3D清除以配置的标准件
      }
      if (this.$refs.ScreenConfigRef.addForm.screen_display_type === 1) {
        this.$refs.ScreenConfigRef.addForm.screen_3D_domain = '' // 切换到2D清除域名地址
      }
      console.log('保存自定义大屏data', data)
      if (this.id) {
        data._id = this.id
        const res = await systemapi.updateBigScreenCustomization(data)
        if (res && res.Code === 200) {
          this.defaultForm()
          this.RefreshBigScreent()
        }
      } else {
        const res = await systemapi.addBigScreenCustomization(data)
        if (res && res.Code === 200) {
          this.$emit('closeDialog', false)
          this.RefreshBigScreent()
        }
      }
    },
    RefreshBigScreent () {
      this.setRefreshBigScreent(true) // 启动刷新
      this.$store.dispatch('menuList/fngetGroupBigScreen')
    },
    // 从模板导入
    async openScreenTemplate () {
      const res = await systemapi.getBigScreenTemplate()
      console.log(res)
      this.bigScreenTemplateList = res && res.Code === 200 ? res.Data : []
      this.isScreenTemplate = true
    },
    // 导入
    importBigScreen (item) {
      // console.log(item)
      this.screenConfigData = item
      this.isScreenTemplate = false
    },
    // 返回
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
  <style scoped lang="less">
  .box-card {
    overflow: auto;
  position: relative;
  padding-bottom: 40px;
  text-align: left;
  min-height: 500px;

  .checkButton {
    position: absolute;
    right: 0px;
    bottom: 0;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
    bottom: 0;

  }

  .checkButton3 {
    position: absolute;
    left: 0px;
    bottom: 0;

  }
}
  // .content_box {
  //   width: 100%;
  //   background: #fafafa;
  //   border: 1px solid #e6e6e6;
  //   display: flex;
  //   align-items: center;
  //   position: relative;
  //   overflow: hidden;
  //   box-sizing: border-box;
  //   .left_standard_box {
  //     width: 350px;
  //     height: 670px;
  //     background: #fafafa;
  //     border: 1px solid #e6e6e6;
  //     // transform: scale(0.8);
  //     display: flex;
  //     flex-direction: column;
  //     .title_box {
  //       width: 100%;
  //       height: 44px;
  //       background: #02a6fb;
  //       font-size: 18px;
  //       font-weight: 700;
  //       color: #ffffff;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //     .search_box {
  //       width: 100%;
  //       padding: 10px;
  //       box-sizing: border-box;
  //       .el-input {
  //         width: 100%;
  //         height: 36px;
  //         background: #ffffff;
  //         border-radius: 5px 5px 5px 5px;
  //         opacity: 1;
  //         border: 1px solid #e6e6e6;
  //       }
  //     }
  //     .standard_box {
  //       flex: 1;
  //       overflow-y: auto;
  //       padding: 10px;
  //       box-sizing: border-box;
  //       .standard_type_item {
  //         .standard_title {
  //           height: 40px;
  //           background: #ffffff;
  //           border-radius: 5px 5px 5px 5px;
  //           padding: 10px;
  //           box-sizing: border-box;
  //           cursor: pointer;
  //           .standard_title_span {
  //             display: inline-block;
  //             height: 100%;
  //             font-size: 16px;
  //             color: #4d4d4d;
  //             display: flex;
  //             &::before {
  //               display: block;
  //               content: "";
  //               width: 3px;
  //               height: 100%;
  //               background-color: #01a6fb;
  //               margin-right: 10px;
  //             }
  //           }

  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //         }
  //         .standard_type_item_box {
  //           display: flex;
  //           flex-wrap: wrap;
  //           align-items: flex-start;
  //           justify-content: space-between;
  //           margin-top: 10px;
  //           .standard_item {
  //             width: 48%;
  //             margin-bottom: 10px;
  //             background: #ffffff;
  //             box-shadow: 0px 0px 5px 0px rgba(2, 166, 251, 0.1);
  //             border-radius: 5px 5px 5px 5px;
  //             cursor: pointer;
  //             padding: 10px;
  //             box-sizing: border-box;
  //             .title {
  //               font-size: 14px;
  //               color: #666666;
  //               margin-bottom: 10px;
  //             }
  //             .item_box {
  //               width: 100%;
  //               height: 97px;
  //               background: #ffffff;
  //               border-radius: 0px 0px 0px 0px;
  //               opacity: 0.3;
  //               border: 1px solid #01a6fb;
  //               display: flex;
  //               align-items: center;
  //               justify-content: space-between;
  //               img {
  //                 max-width: 100%;
  //                 max-height: 100%;
  //                 object-fit: contain;
  //               }
  //             }
  //           }
  //           .current {
  //             border: 1px solid #02a6fb;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   .main_body_box {
  //     width: 1100px;
  //     height: 640px;
  //     border: 1px solid rgba(0, 0, 0, 0.5);
  //     margin-left: 30px;
  //     #mapBox {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  //   .right-drawer_box {
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     right: 0;
  //     width: 15%;
  //     background-color: #fff;
  //     box-shadow: 10px 10px 10px 10px rgba(2, 166, 251, 0.1);
  //     padding: 10px;
  //     box-sizing: border-box;
  //     .close {
  //       position: absolute;
  //       top: 50%;
  //       left: -15px;
  //       transform: translate(0, -50%);
  //       width: 30px;
  //       height: 30px;
  //       border-radius: 50%;
  //       // border: 0.5px solid #eeeff1;
  //       background: #fff;
  //       text-align: center;
  //       line-height: 30px;
  //       cursor: pointer;
  //       .iconfont {
  //         font-size: 20px;
  //       }
  //     }
  //     /deep/ .standardForm {
  //       .el-select,
  //       .el-input {
  //         width: 100%;
  //         margin-right: 10px;
  //       }
  //     }
  //   }
  // }
  </style>
